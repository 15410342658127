import * as globalTypes from "@/base/store-common/types.js";
import * as types from "./types.js";


const initialState = {
    pollsResultsByClassId: {},
    pollResultsById: {},
    closedPolls: [],
    page: 0,
    hasMorePages: false,
    isClosedPollsLoading: false,
    isClosedPollsLoaded: false,
};

export default (state, action) => {
    switch (action.type) {
        case types.SET_CLOSED_POLLS_LOADING: {
            return {
                ...state,
                isClosedPollsLoading: true,
            };
        }

        case types.SET_POLL_RESULT_BY_ID_LOADING: {
            const { pollId } = action.payload;

            const prevByPollId = state.pollResultsById[pollId] || {};

            return {
                ...state,
                pollResultsById: {
                    ...state.pollResultsById,
                    [pollId]: {
                        ...prevByPollId,
                        isLoading: true,
                    },
                },
            };
        }

        case types.SET_POLL_RESULT_BY_ID: {
            const { pollId, data, error } = action.payload;

            return {
                ...state,
                pollResultsById: {
                    ...state.pollResultsById,
                    [pollId]: {
                        data,
                        error,
                        isLoaded: true,
                        isLoading: false,
                    },
                },
            };
        }

        case types.SET_CLOSED_POLLS: {
            const {
                closedPolls,
                hasMorePages,
            } = action.payload;

            const allPolls = [
                ...closedPolls,
            ];

            return {
                ...state,
                closedPolls: allPolls,
                page: state.page,
                hasMorePages,
                isClosedPollsLoading: false,
                isClosedPollsLoaded: true,
            };
        }

        case types.ADD_CLOSED_POLLS: {
            const {
                closedPolls,
                hasMorePages,
            } = action.payload;

            const allPolls = [
                ...state.closedPolls,
                ...closedPolls,
            ];

            return {
                ...state,
                closedPolls: allPolls,
                page: state.page,
                hasMorePages,
                isClosedPollsLoading: false,
                isClosedPollsLoaded: true,
            };
        }

        case types.CLEAR_CLOSED_POLLS: {
            return {
                ...state,
                closedPolls: [],
                page: 0,
                hasMorePages: false,
                isClosedPollsLoading: false,
                isClosedPollsLoaded: false,
            };
        }

        case types.SET_POLL_RESULT_BY_CLASS_ID_LOADING: {
            const { pollsResultsByClassId } = state;
            const { pollId, classId } = action.payload;

            return {
                ...state,
                pollsResultsByClassId: {
                    ...pollsResultsByClassId,
                    [pollId]: {
                        ...pollsResultsByClassId[pollId],
                        [classId]: {
                            isLoading: true,
                            data: null,
                            error: "",
                        },
                    },
                },
            };
        }

        case types.SET_POLL_RESULT_BY_CLASS_ID: {
            const { pollsResultsByClassId } = state;
            const {
                pollId,
                classId,
                data,
                error,
            } = action.payload;

            return {
                ...state,
                pollsResultsByClassId: {
                    ...pollsResultsByClassId,
                    [pollId]: {
                        ...pollsResultsByClassId[pollId],
                        [classId]: {
                            isLoading: false,
                            data,
                            error,
                        },
                    },
                },
            };
        }

        case types.CLEAR_POLLS_RESULTS: {
            return {
                ...state,
                pollsResultsByClassId: {},
            };
        }

        /* --- */

        case globalTypes.CLEAR_AFTER_SIGNOUT: {
            return { ...initialState };
        }

        /* --- */

        default: {
            return state || { ...initialState };
        }
    }
};
