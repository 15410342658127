import * as types from "./types.js";

export const setStory = (payload) => ({
    type: types.SET_JUICE_STORY,
    payload,
});

/* --- */

export const setStoriesInfoByJuiceIdLoading = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_BY_JUICE_ID_LOADING,
    payload,
});

export const setStoriesInfoByJuiceId = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_BY_JUICE_ID,
    payload,
});

export const setStoryInfoStoryRate = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_RATING,
    payload,
});

export const setStoryInfoStoryOpened = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_OPENED,
    payload,
});

export const setStoryInfoStoryVideoViewed = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_VIDEO_VIEWED,
    payload,
});

export const setStoryInfoStoryExtraJuiceRate = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_EXTRA_JUICE_RATING,
    payload,
});

export const setStoryInfoStoryAnswer = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_ANSWER,
    payload,
});

export const setStoryInfoStoryPollAnswer = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER,
    payload,
});

export const setStoryInfoStoryPollAnswerV2 = (payload) => ({
    type: types.SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER_V2,
    payload,
});

/* --- */

export default {};
