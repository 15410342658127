export const SET_JUICE_STORY = "SET_JUICE_STORY";

export const SET_JUICE_STORIES_INFO_BY_JUICE_ID_LOADING = "SET_JUICE_STORIES_INFO_BY_JUICE_ID_LOADING";
export const SET_JUICE_STORIES_INFO_BY_JUICE_ID = "SET_JUICE_STORIES_INFO_BY_JUICE_ID";

export const SET_JUICE_STORIES_INFO_STORY_OPENED = "SET_JUICE_STORIES_INFO_STORY_OPENED";
export const SET_JUICE_STORIES_INFO_STORY_VIDEO_VIEWED = "SET_JUICE_STORIES_INFO_STORY_VIDEO_VIEWED";
export const SET_JUICE_STORIES_INFO_STORY_RATING = "SET_JUICE_STORIES_INFO_STORY_RATING";
export const SET_JUICE_STORIES_INFO_STORY_EXTRA_JUICE_RATING = "SET_JUICE_STORIES_INFO_STORY_EXTRA_JUICE_RATING";
export const SET_JUICE_STORIES_INFO_STORY_ANSWER = "SET_JUICE_STORIES_INFO_STORY_ANSWER";
export const SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER = "SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER";
export const SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER_V2 = "SET_JUICE_STORIES_INFO_STORY_POLL_ANSWER_V2";

export default {};
